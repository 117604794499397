import React from "react"
import usePosts from "../hooks/use-posts"
// import { Link } from "gatsby"
import { css } from "styled-components"
import Layout from "../layouts/layout"
import PostPreview from "../layouts/post-preview"
// import Image from "../components/image";
import SEO from "../components/seo"
import Hero from "../layouts/home-hero"
import { UiText, Grid, Card, Button } from "../components"
import Search from "../layouts/search"
import DeviceSizes from "../layouts/progressive-web-app"
import Preprocess from "../layouts/preprocess-data"
import ReadLink from "../components/read-link"
import IconWebDev from "../layouts/icon-web-dev"
import IconMobileDev from "../layouts/icon-mobile-dev"
import IconSeo from "../layouts/icon-seo"
import IconDataPreprocess from "../layouts/icon-data-process"
const IndexPage = () => {
  const posts = usePosts()
  return (
    <>
      <Layout>
        <SEO title="Home" />
        <Hero />
        <div
          css={css`
            max-width: 1170px;
            margin: 0 auto;
            padding: 16px 8px;
          `}
        >
          <UiText
            as="h2"
            variant="h2"
            fontSize="36px"
            textColor="#555"
            css={css`
              text-align: center;
              line-height: 1.4;
            `}
          >
            We Develop Web and Mobile App
          </UiText>
          <UiText
            textColor="#555"
            variant="content"
            css={css`
              text-align: center;
            `}
          >
            Digitize your management with tailor-made web and mobile solutions,
            based on current technologies.
          </UiText>
          <Grid>
            <Grid.Col l={6}>
              <Card
                css={css`
                  display: flex;
                  align-items: center;
                  p {
                    margin: 0;
                  }
                `}
                elevation={1}
              >
                <IconWebDev />
                <div>
                  <UiText textColor="#555" variant="contentBold">
                    Web Design and Development
                  </UiText>
                  <UiText textColor="#555" variant="content">
                    Digitize your management with tailor-made web and mobile
                    solutions, based on current technologies.
                  </UiText>
                </div>
              </Card>
            </Grid.Col>
            <Grid.Col
              l={6}
              css={css`
                @media (min-width: 1024px) {
                  margin-top: 0;
                }
              `}
            >
              <Card
                css={css`
                  display: flex;
                  align-items: center;
                  p {
                    margin: 0;
                  }
                `}
                elevation={1}
              >
                <IconMobileDev />
                <div>
                  <UiText textColor="#555" variant="contentBold">
                    Web Design and Development
                  </UiText>
                  <UiText textColor="#555" variant="content">
                    Digitize your management with tailor-made web and mobile
                    solutions, based on current technologies.
                  </UiText>
                </div>
              </Card>
            </Grid.Col>
            <Grid.Col l={6}>
              <Card
                css={css`
                  display: flex;
                  align-items: center;
                  p {
                    margin: 0;
                  }
                `}
                elevation={1}
              >
                <IconSeo />
                <div>
                  <UiText textColor="#555" variant="contentBold">
                    Web Design and Development
                  </UiText>
                  <UiText textColor="#555" variant="content">
                    Digitize your management with tailor-made web and mobile
                    solutions, based on current technologies.
                  </UiText>
                </div>
              </Card>
            </Grid.Col>
            <Grid.Col l={6}>
              <Card
                css={css`
                  display: flex;
                  align-items: center;
                  p {
                    margin: 0;
                  }
                `}
                elevation={1}
              >
                <IconDataPreprocess />
                <div>
                  <UiText textColor="#555" variant="contentBold">
                    Web Design and Development
                  </UiText>
                  <UiText textColor="#555" variant="content">
                    Digitize your management with tailor-made web and mobile
                    solutions, based on current technologies.
                  </UiText>
                </div>
              </Card>
            </Grid.Col>
          </Grid>
          <UiText
            variant="contentBold"
            css={css`
              text-align: center;
            `}
          >
            <ReadLink
              to="/about"
              css={css`
                color: #63c46e;
              `}
            >
              Learn More &rarr;
            </ReadLink>
          </UiText>
          <Grid>
            <Grid.Col s={12} l={5}>
              <UiText as="h2" variant="h2" textColor="#555">
                We Develop Web and Mobile App
              </UiText>
              <UiText textColor="#555" variant="content">
                Digitize your management with tailor-made web and mobile
                solutions, based on current technologies.
              </UiText>
              <ReadLink
                to="/about"
                css={css`
                  color: #63c46e;
                `}
              >
                Learn More &rarr;
              </ReadLink>
            </Grid.Col>
            <Grid.Col s={12}>
              <DeviceSizes />
            </Grid.Col>
          </Grid>
          <Grid
            css={css`
              flex-direction: row-reverse;
            `}
          >
            <Grid.Col s={12}>
              <UiText as="h2" variant="h2" textColor="#555">
                Web Design and Development
              </UiText>
              <UiText textColor="#555" variant="content">
                Let us create your dynamic, SEO (SEO) and responsive design web
                showcase, in compliance with current standards.
              </UiText>
              <ReadLink
                to="/about"
                css={css`
                  color: #63c46e;
                `}
              >
                Learn More &rarr;
              </ReadLink>
            </Grid.Col>
            <Grid.Col s={12} l={5}>
              <Search />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col s={12} l={4}>
              <UiText as="h2" variant="h2" textColor="#555">
                AI Training Data Services
              </UiText>
              <UiText textColor="#555" variant="content">
                We collect, preprocess and provide high-quality custom datasets
                for projects.
              </UiText>
              <ReadLink
                to="/about"
                css={css`
                  color: #63c46e;
                `}
              >
                Learn More &rarr;
              </ReadLink>
            </Grid.Col>
            <Grid.Col s={12}>
              <Preprocess />
            </Grid.Col>
          </Grid>
          <Card
            css={css`
              display: flex;
              justify-content: space-around;
              align-items: center;
              background-image: url("/images/square.svg");
              background-repeat: no-repeat;
              background-color: #63c46e;
              flex-wrap: wrap;
            `}
          >
            <UiText
              as="h3"
              variant="content"
              fontSize="36px"
              textColor="#fff"
              css={css`
                text-align: center;
                line-height: 1.4;
              `}
            >
              We Develop Web and Mobile App
            </UiText>
            <Button>Get Started</Button>
          </Card>

          <UiText as="h2" variant="h2" textColor="#555">
            Read our blog
          </UiText>
          {posts.map(post => (
            <PostPreview key={post.slug} post={post} />
          ))}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
