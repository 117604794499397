import React from "react"
import { css } from "styled-components"
const DesignComponent = () => {
  return (
    <div
      css={css`
        width: 100px;
      `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
      >
        <path fill="#63c46e" d="M56.667,43.333V70h-40V16.667h20V10h-20C13.001,10,10,13.001,10,16.667v66.667C10,86.999,13.001,90,16.667,90h40  c3.665,0,6.666-3.001,6.666-6.667v-40H56.667z M40,83.333h-6.667C31.491,83.333,30,81.843,30,80s1.491-3.333,3.333-3.333H40  c1.843,0,3.333,1.49,3.333,3.333S41.843,83.333,40,83.333z" />
        <path fill="#63c46e" d="M89.021,20.977l-9.311-9.31l-3.535,3.539l8.135,8.128l-8.131,8.131L79.714,35l9.31-9.31  C90.325,24.388,90.325,22.275,89.021,20.977z" />
        <path fill="#63c46e" d="M57.158,15.202l-3.535-3.535l-9.313,9.31c-1.302,1.302-1.302,3.418,0,4.713L53.623,35l3.535-3.535l-8.135-8.131  L57.158,15.202z" />
        <polygon fill="#63c46e" points="65.817,10 70.853,10 67.522,36.667 62.483,36.667 " />
      </svg>
    </div>
  )
}

export default DesignComponent
