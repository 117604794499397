import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import DesignComponent from "./design-component"
import { UiText, Input, Button } from "../components"

const ImageBackground = styled("div")`
  /* background-image: url("/images/Page-Turner.svg"); */
  /* background-position: top 20% center; */
  background-image: linear-gradient(to top, #63c46e 2rem, #63c46e);
  margin-top: 0;
  background-size: cover;
  background-color: #ffffff;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top left;
  height: 100%;
  position: relative;
  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }

  .custom-shape-divider-bottom-1614034066 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1614034066 svg {
    position: relative;
    display: block;
    width: calc(119% + 1.3px);
    height: 108px;
  }

  .custom-shape-divider-bottom-1614034066 .shape-fill {
    fill: #68cf89;
  }

  .custom-shape-divider-bottom-1614058227 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-bottom-1614058227 svg {
    position: relative;
    display: block;
    width: calc(260% + 1.3px);
    height: 20px;
  }

  .custom-shape-divider-bottom-1614058227 .shape-fill {
    fill: #ffffff;
  }
`
const TextBox = styled("div")`
  background-image: linear-gradient(to top, #63c46e 2rem, #ddbbff00);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: calc(56px);
  @media (min-width: 1024px) {
    padding-top: 32px;
  }
  /* margin-top: 0; */
  padding-bottom: 108px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @media (min-width: calc(550px + 10vw)) {
    padding-left: calc((100vw - 550px) / 2);
    padding-right: calc((100vw - 550px) / 2);
  }
  h1 {
    text-shadow: 1px 1px 3px #eeddff66;
  }
  p,
  a {
    margin-top: 0;
  }
  a {
    color: #fff;
  }
  a {
    margin-top: 0.5rem;
  }
`
const Hero = () => {
  return (
    <ImageBackground>
      <TextBox>
        <div
          css={css`
            max-width: 1170px;
            margin: 0 auto;
            padding: 0 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-image: url("/images/square.svg");
            background-repeat: no-repeat;
            @media (min-width: 1024px) {
              flex-direction: row;
              justify-content: space-between;
            }
          `}
        >
          <div
            css={css`
              @media (min-width: 1024px) {
                margin-right: 8px;
              }
            `}
          >
            <UiText
              as="h1"
              variant="h1"
              textColor="#fff"
              css={css`
                font-size: 42px;
                line-height: 1;
                @media (min-width: 1024px) {
                  font-size: 56px;
                  line-height: 1.4;
                }
              `}
            >
              Team Slopes &lt;/&gt;
            </UiText>
            <UiText variant="content" textColor="#fff">
              Entrust us with the realization of your responsive design website
              and web app, SEO (Referencing), and or mobile app in compliance
              with current standards. <br />{" "}
              <Link to="/about">Learn about us &rarr;</Link>
            </UiText>

            <form
              css={css`
                display: flex;
                align-items: center;
                width: 100%;
              `}
            >
              <Input placeholder="you@yoursite.com" />
              <Button>Get Started</Button>
            </form>
          </div>
          <DesignComponent />
        </div>
        <div class="custom-shape-divider-bottom-1614034066">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <div class="custom-shape-divider-bottom-1614058227">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </TextBox>
    </ImageBackground>
  )
}

export default Hero
