import React from "react"
import { css } from "styled-components"
const DesignComponent = () => {
  return (
    <div
      css={css`
        width: 100px;
      `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
      >
        <path
          fill="#63c46e"
          d="M13.3,85h31.8c-0.8-0.6-1.5-1.3-2.2-2c-1.6-1.6-2.9-3.3-4.1-5.1H14.6c-2.8,0-5-2.2-5-5V26.5h71.2v9.1  c1.9,1.2,3.6,2.6,5.2,4.2c0.7,0.7,1.3,1.4,1.9,2.1V16.6c0-6.1-4.9-11-11-11H13.5c-6.1,0-11,4.9-11,11v57.5C2.5,80.1,7.3,85,13.3,85z   M72.8,11.8c2.5,0,4.5,2,4.5,4.5c0,2.5-2,4.5-4.5,4.5c-2.5,0-4.5-2-4.5-4.5C68.3,13.8,70.3,11.8,72.8,11.8z M59.6,11.8L59.6,11.8  L59.6,11.8c2.2,0,4,1.5,4.4,3.6c0.1,0.3,0.1,0.6,0.1,0.9c0,0.3,0,0.6-0.1,0.9c-0.4,2.1-2.2,3.6-4.4,3.6h0h0c-2.5,0-4.5-2-4.5-4.5  C55.1,13.8,57.1,11.8,59.6,11.8z M46.4,11.8c2.5,0,4.5,2,4.5,4.5c0,2.5-2,4.5-4.5,4.5c-2.5,0-4.5-2-4.5-4.5  C41.9,13.8,43.9,11.8,46.4,11.8z M67.2,61.4c0,1.5-1.2,2.8-2.8,2.8s-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8S67.2,59.8,67.2,61.4z   M59.6,61.4c0,1.5-1.2,2.8-2.8,2.8c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8C58.4,58.6,59.6,59.8,59.6,61.4z M74.9,61.4  c0,1.5-1.2,2.8-2.8,2.8s-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8S74.9,59.8,74.9,61.4z M81.2,44.6c-9.2-9.2-24.2-9.2-33.4,0  s-9.2,24.2,0,33.4c7.9,7.9,19.9,9,29,3.4L88,92.8c2.2,2.2,5.7,2.2,7.8,0c2.2-2.2,2.2-5.7,0-7.8L84.7,73.7  C90.2,64.6,89.1,52.5,81.2,44.6z M75.7,72.6c-6.2,6.2-16.3,6.2-22.5,0c-6.2-6.2-6.2-16.3,0-22.5c6.2-6.2,16.3-6.2,22.5,0  C81.9,56.3,81.9,66.4,75.7,72.6z"
        />
      </svg>
    </div>
  )
}

export default DesignComponent
